import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { Deposit } from '../../models';
import * as pageActions from '../actions/deposits.action';

export interface DepositsState {
  entities: { [id: number]: Deposit };
  municipalities: number[]; // check if municipalities has deposits service
  loaded: number[];
  loading: number[];
  error?: any;
}

export const initialState: DepositsState = {
  entities: {},
  municipalities: [21, 5, 14], //21: Zwolle
  loaded: [],
  loading: [],
};

export const depositReducer = createReducer(
  initialState,
  on(pageActions.LoadDeposits, (state: DepositsState, { year }) => {
    const y = year || new Date().getFullYear();
    return {
      ...state,
      loading: [...state.loading, y],
    };
  }),
  on(pageActions.LoadDepositsSuccess, (state: DepositsState, { deposits, year }) => {
    const y = year || new Date().getFullYear();
    const entities = ToEntities(deposits, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: state.loading.filter(a => a !== y),
      loaded: [...state.loaded, y],
    };
  }),
  on(pageActions.LoadDepositsFail, (state: DepositsState, { error, year }) => {
    const y = year || new Date().getFullYear();
    return {
      ...state,
      loading: state.loading.filter(a => a !== y),
      loaded: state.loaded.filter(a => a !== y),
      error,
    };
  })
);

export const getDepositsEntities = (state: DepositsState) => state.entities;
export const getDepositsLoading = (state: DepositsState) => state.loading;
export const getDepositsLoaded = (state: DepositsState) => state.loaded;
export const getDepositsMunicipalities = (state: DepositsState) => state.municipalities;
