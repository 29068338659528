<header class="section-inset-y insight-header">
  <div class="container">
    <div class="row">
      <main class="col-md-5 insight-header__main">
        <h1 class="text-secondary">{{ 'inzicht-stortgedrag-titel' | fromDictionary }}</h1>
        <p class="insight-header__main__text">{{ 'inzicht-stortgedrag-intro' | fromDictionary }}</p>
      </main>
      <aside class="col-md-5 offset-md-2 col-lg-4 offset-lg-3 insight-header__aside">
        <ul>
          <li class="mb-1">
            <a
              [routerLink]="['/inzicht']"
              class="no--link"
              [queryParams]="{ viewMode: 1, year: currentYear }"
              (click)="gotoYear.emit(true)"
            >
              <app-deposit-card
                [title]="'inzicht-stortgedrag-card-jaar-titel' | fromDictionary"
                [type]="'secondary'"
                [amount]="amountCurrentYear$ | async"
              ></app-deposit-card>
            </a>
          </li>
          <li class="mb-1">
            <app-deposit-card
              [title]="'inzicht-stortgedrag-card-maand-titel' | fromDictionary"
              [type]="'primary'"
              [amount]="amountCurrentMonth$ | async"
              [link]="['/inzicht', currentYear.toString(), (currentMonth + 1).toString()]"
            ></app-deposit-card>
          </li>
          <li>
            <app-deposit-card
              [title]="'inzicht-stortgedrag-card-recent-titel' | fromDictionary"
              [deposit]="latestDeposit$ | async"
              [type]="'secondary-light'"
            ></app-deposit-card>
          </li>
        </ul>
      </aside>
    </div>
    <figure class="insight-header__figure d-none d-md-block">
      <fstr-image
        [imageSizes]="[800, 1400, 1900]"
        [alt]=""
        [image]="{ url: 'https://www.rova.nl/media/_DSF1398.jpg', anchor: 'middleright' }"
        [loading]="'eager'"
        [objectFitMode]="true"
        [decorative]="true"
      ></fstr-image>
    </figure>
  </div>
</header>
